/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import Grid from '@material-ui/core/Grid';

import ApexCharts from 'apexcharts'

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import ModalNewUser from 'components/modal-new-user/modal-new-user';
import Heatmap from 'components/heatmap/heatmap';
import { requireAuthToken, getMyUsers } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '129px 0 35px',
		[theme.breakpoints.down('md')]: {
			padding: '150px 0 70px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '100px 0 70px',
		},
	},
	sectionBar: {
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center'
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '24%',
		minWidth: '18px',
		margin: '0 !important',
	},
	articles: {
		margin: '0 -77px',
		[theme.breakpoints.down('lg')]: {
			margin: '0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '0 -15px',
		}
	},
	graph: {
		margin: '35px auto',
		boxShadow: '-8px -16px 24px rgba(227, 230, 236, 0.5), 16px 8px 24px rgba(227, 230, 236, 0.5)',
		borderRadius: '32px',
		padding: '1em',
		backgroundColor: 'white',
		height: '90%'
	},
	articlesActions: {
		marginBottom: '13px',
		paddingLeft: '117px',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '44px'
		},
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		},
		'& button': {
			textAlign: 'left',
			padding: '0',
			display: 'inline-block',
			[theme.breakpoints.down('sm')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '12px',
			},
			'& i': {
				[theme.breakpoints.down('xs')]: {
					width: '10px',
					height: '10px',
				}	
			},
			'&:nth-child(1)': {
				width: '125px',
				[theme.breakpoints.down('md')]: {
					width: '90px',
				},
				[theme.breakpoints.down('sm')]: {
					width: '69px',
				}
			},
			'&:nth-child(2)': {
				width: '411px',
				[theme.breakpoints.down('md')]: {
					width: '259px',
				},
				[theme.breakpoints.down('sm')]: {
					width: '193px',
				}

			},
			'&:nth-child(3)': {
				width: '600px',
				[theme.breakpoints.down('md')]: {
					width: '300px',
				}
			}
		}
	},
	sectionPagination: {
		marginTop: '84px',
		[theme.breakpoints.down('xs')]: {
			margin: '90px -15px 0'
		},
		'& .MuiPagination-ul': {
			justifyContent: 'center'
		},
		'& .MuiButtonBase-root': {
			width: '34px',
			height: '34px',
			lineHeight: '34px',
			fontSize: '16px',
			border: 'none',
			background: '#fff',
			margin: '0 5px',
		},
		'& Mui-disabled': {
			background: '#fff'
		},
		'& .Mui-selected': {
			backgroundColor: '#00CCEE !important',
			color: '#fff',
			borderRadius: '50%',
		},
		'& li:last-child': {
			'& button': {
				background: 'none'
			}
		}
	},
	sectionButton: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '0',
			minWidth: '0',
			width: '56px',
			height: '56px',
			padding: '0',
			borderRadius: '50%',
		},
		'& i': {
			display: 'none',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			[theme.breakpoints.down('xs')]: {
				display: 'inline-block',
			},
		}

	}


}));

const AreaIDs = ["Hangar", "Cabin", "Cockpit", "Lavatory", "Baggage", "Private room"];
const PlaneIDs = ["Phenom", "Latitude", "Global 6000", "Challenger 350", "Challenger 650"];

async function getUsersInStatistics(data) {
	const userData = await getMyUsers(false, false, false);

	const mapUserIdToUsername = data.reduce((result, visit) => {
		const user = userData.find(user => user.user_id === visit.userId);

		// Assign the username or user id to the result object
		result[visit.userId] = user ? user.username : visit.userId;

		return result;
	}, {});

	console.log("Users in statistics:", mapUserIdToUsername);

	return mapUserIdToUsername;
}

const Statistics = () => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [fullStatisticsData, setFullStatisticsData] = useState([]);
	const [statisticsData, setStatisticsData] = useState([]);
	const [userList, setUserList] = useState([]);
	const [userObject, setUserObject] = useState({});
	const [pieChart, setPieChart] = useState(null);
	const [columnChart, setColumnChart] = useState(null);

	const [filterUserId, setFilterUserId] = useState("ignoreInitial");
	const [filterMinDate, setFilterMinDate] = useState(null);
	const [filterMaxDate, setFilterMaxDate] = useState(null);

	function convertToCSV(data) {
		const columns = ["User", "Timestamp", "Plane", "Area", "Time spent"];
		let csv = columns.join(",") + "\n";
	
		data.forEach(obj => {
			const user = userObject[obj.userId];
			const timestamp = new Date(obj.timestamp * 1000).toLocaleString().replace(/,/g, ""); // Convert Unix epoch to date/time string
			const plane = PlaneIDs[obj.planeId];
	
			obj.areas.forEach(area => {
				const areaId = AreaIDs[area.areaId];
				const timeSpent = area.timeSpent;
	
				const row = [user, timestamp, plane, areaId, timeSpent];
				csv += row.join(",") + "\n";
			});
		});
	
		return csv;
	}	

	useEffect(()=>{
		async function fetchStatistics(params) {
			const authToken = await requireAuthToken(false, true);

			const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/statistics`, {
			  // Adding method type
			  method: "GET",
		  
			  // Adding headers to the request
			  headers: {
				"Content-type": "application/json; charset=UTF-8",
				"Authorization": `Bearer ${authToken}`
			  }
			});
		  
			if (!response.ok) {
			  throw new Error(`HTTP error! status: ${response.status}`);
			}
		  
			const data = await response.json();
		  
			console.log(data);
			setFullStatisticsData(data);
			setStatisticsData(data);
			const usersObject = await getUsersInStatistics(data);
			setUserObject(usersObject);
			setUserList(Object.values(usersObject));
			
			// Draw charts
			renderMostVisitedChart(data);
			renderTimeSpentChart(data);
			if (filterUserId == "ignoreInitial") {
				setFilterUserId(null);
				return;
			}
		}

		fetchStatistics();
	}, []);

	function onChangeUserId(e, value) {
		console.log("onChangeUserId " + value);
		const foundKey = Object.keys(userObject).find(key => userObject[key] === value);
		
		setFilterUserId(foundKey);
	}
	
	function onChangeMinDate(value) {
		console.log("onChangeMinDate ", value);
		setFilterMinDate(value);
	}
	
	function onChangeMaxDate(value) {
		console.log("onChangeMaxDate ", value);
		setFilterMaxDate(value);
	}

	// Update graphs when the filters change
	useEffect(() => {
		if (filterUserId == "ignoreInitial") return;

		// Filter first by user id
		const filteredByUserId = filterUserId ? fullStatisticsData.filter(visit => visit.userId == filterUserId) : fullStatisticsData;
		
		// Now filter by minimum date
		let filteredByMinDate;

		try {
			const minUnixTimestamp = filterMinDate.unix();
			filteredByMinDate = filteredByUserId.filter(visit => visit.timestamp > minUnixTimestamp);
		} catch (error) {
			filteredByMinDate = filteredByUserId;
		}

		// Now filter by maximum date
		let filteredByMaxDate;

		try {
			const maxUnixTimestamp = filterMaxDate.unix();
			filteredByMaxDate = filteredByMinDate.filter(visit => visit.timestamp < maxUnixTimestamp);
		} catch (error) {
			filteredByMaxDate = filteredByMinDate;
		}

		// Update graphs
		setStatisticsData(filteredByMaxDate);
		renderMostVisitedChart(filteredByMaxDate);
		renderTimeSpentChart(filteredByMaxDate);
	}, [filterUserId, filterMinDate, filterMaxDate]);

	function downloadCSV() {
		console.log("Downloading CSV....");
		const csvContent = convertToCSV(statisticsData);

		const blob = new Blob([csvContent], { type: 'text/csv' });
	
		// Create download url
		const a = document.createElement('a');
		a.href = URL.createObjectURL(blob);
		a.download = `stats_${new Date().toLocaleString()}.csv`;
	
		// Add the link to the document and click on it
		document.body.appendChild(a);
		a.click();
	
		// Remove link and free resources
		document.body.removeChild(a);
		URL.revokeObjectURL(a.href);
	}

	function renderTimeSpentChart(data) {
		let totalTimeSpent = new Array(PlaneIDs.length).fill(0);
		let entryCount = new Array(PlaneIDs.length).fill(0);
	
		// Obtain total time spent and number of entries
		data.forEach(visit => {
			try {
				visit.areas.forEach(area => {
					totalTimeSpent[visit.planeId] += area.timeSpent;
					entryCount[visit.planeId] += 1;
				});
			} catch (e) {
				console.log(e);
			}
		});
	
		// Calculate average
		const averageTimeSpent = totalTimeSpent.map((totalTime, index) => {
			const count = entryCount[index];
			if (count !== 0) {
				return totalTime / count;
			} else {
				return 0;
			}
		});
		console.log(averageTimeSpent);
	
		var options = {
			series: averageTimeSpent,
			chart: {
				height: '80%',
				type: 'pie',
			},
			labels: PlaneIDs,
			theme: {
				monochrome: {
					enabled: true
				}
			},
			plotOptions: {
				pie: {
					dataLabels: {
						offset: -5
					}
				}
			},
			title: {
				text: "Time spent in average (s)"
			},
			fill: {
				colors: ['#1C3149', '#3B536C', '#496C8B', '#698FAB', '#86B4CE']
				//colors: ['#262524', '#444240', '#777673', '#CDCBC9', '#CDCBC9']
			  },
			dataLabels: {
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex]
					return [name, val.toFixed(1) + '%']
				}
			},
			legend: {
				show: false
			}
		};
	
		if (pieChart) {
			console.log("Updating series...");
			console.log(pieChart);
			pieChart.updateSeries(averageTimeSpent, true);
		} else {
			var timeSpentChart = new ApexCharts(document.querySelector("#timeSpentChart"), options);
			timeSpentChart.render();
			setPieChart(timeSpentChart);
		}
	}

	function renderMostVisitedChart(data) {
		let totalVisits = new Array(PlaneIDs.length).fill(0);
	
		data.forEach(visit => {
			// Skip entries outside filter bounds
			//if (user.timestamp < minFilterDate || user.timestamp > maxFilterDate) return;
			try {
				totalVisits[visit.planeId] += 1;
			} catch (e) {
				console.log(e);
			}
		});
	
		// Find the highest value to highlight the bars that match it
		const max = Math.max(...totalVisits);
	
		// Format the values as the graph expects them
		const values = PlaneIDs.map((planeID, index) => {
			const plane = {
				x: planeID,
				y: totalVisits[index]
			};
	
			// Verifica si el valor es igual al máximo
			if (totalVisits[index] === max) {
				plane.fillColor = "#C1AF7A";
			} else {
				plane.fillColor = "#3B536C";
			}
	
			return plane;
		});
	
		var mostVisitedOptions = {
			chart: {
				type: 'bar'
			},
			series: [{
				name: 'Total visits',
				data: values
			}],
			xaxis: {
				type: "category"
			},
			title: {
				text: "Total visits"
			},
		};

		if (columnChart) {
			console.log("Updating series...");
			console.log(columnChart);
			columnChart.updateSeries([{
				name: 'Total visits',
				data: values
			}], true);
		} else {
			var mostVisitedChart = new ApexCharts(document.querySelector("#mostVisitedChart"), mostVisitedOptions);
			mostVisitedChart.render();
			setColumnChart(mostVisitedChart);
		}
	}

	return (
		<LayoutAlt activeNav="statistics">
			<Box className={classes.section}>
				<Box>
					<Container maxWidth="xl">
						<Box className={classes.sectionBar}>
							<Autocomplete
								disablePortal
								id="combo-box-demo"
								variant="outlined" color="secondary" className={classes.modalButton}
								options={userList}
								sx={{ width: 300 }}
								onChange={onChangeUserId}
								renderInput={(params) => <TextField {...params} label="User" variant="outlined"/>}
							/>
							
							<DateTimePicker className={classes.modalButton} onAccept={onChangeMinDate} maxDateTime={filterMaxDate} disableFuture label="From" />
							<DateTimePicker className={classes.modalButton} onAccept={onChangeMaxDate} minDateTime={filterMinDate} disableFuture label="To" />

							<Button onClick={downloadCSV} variant="outlined" color="secondary" className={classes.modalButton}>
								Download CSV
							</Button>

						</Box>
						<Grid container spacing="3" alignItems="stretch" justifyContent="flex-start">
							<Grid item xs={12} md={6}>
								<div id="mostVisitedChart" className={classes.graph}></div>
							</Grid>
							<Grid item xs={12} md={6}>
								<div id="timeSpentChart" className={classes.graph}></div>
							</Grid>
							<Grid item xs={12} md={12}>
								<Heatmap image={null} title={null} data={statisticsData} PlaneIDs={PlaneIDs} AreaIDs={AreaIDs}></Heatmap>
							</Grid>
						</Grid>
						<Box className={classes.articles}>
						</Box>
					</Container>
				</Box>
			</Box>
			
			<Modal
				open={openModal}
				onClose={(e) => { setOpenModal(false) }}
			>
				<div><ModalNewUser onClose={(e) => { setOpenModal(false) }} /></div>
			</Modal>
		</LayoutAlt>
	);
};

export default Statistics;
