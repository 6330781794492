/**
 * External dependencies.
 */
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { FullFileBrowser, ChonkyActions } from 'chonky';

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';

import { fetchMedia, fetchCollection, getMediaDownloadURL, getAppMetadataValue, getProfileData, requireUserID } from 'MyUtils.js'
import boxImage from 'assets/images/placeholder2.jpg';
import placeholderSlides from 'assets/images/placeholderSlides.jpg';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '159px 0 17px',
		[theme.breakpoints.down('md')]: {

		},
		[theme.breakpoints.down('xs')]: {

		}
	},

}));

const Replays = () => {
	const classes = useStyles();
	const refreshInterval = 10; // in seconds

	const [folderChain, setFolderChain] = useState([{ id: 'Replays', name: 'Replays', isDir: true }]);
	const [refreshTimer, setRefreshTimer] = useState(null);
	const [articles, setArticles] = useState(() => {
		let aux = [];
		for (let i = 0; i < 16; i++) {
			aux.push(null);
		}
		return aux;
	});

	async function loadMedia(collection = "", isRefresh = false) {
		const mediaData = await fetchMedia(collection);

		//console.log(data)

		let auxArticles = [];

		if (mediaData.MediaFiles.length > 0) {
			mediaData.MediaFiles.forEach(element => {
				auxArticles.push({
					id: element.Id,
					modDate: element.LastModified,
					name: element.FileName.split('/').pop(),
					draggable: false,
					contentType: element.ContentType,
					downloadUrl: element.DownloadUrl
				});
			});
		}

		if (mediaData.MediaSubCollections.length > 0) {
			mediaData.MediaSubCollections.forEach(element => {
				auxArticles.push({
					id: element.CollectionName,
					name: element.CollectionName.split('/').pop(),
					isDir: true,
					droppable: false,
					draggable: false
				});
			});
		}

		if (isRefresh && folderChain[folderChain.length - 1].id != collection) {
			console.log("Outdated request, don't change page");
			return;
		}

		setArticles(auxArticles);
	};

	const handleAction = useCallback(async (data) => {
		console.log('File action data:', data);

		if (data.id === ChonkyActions.OpenFiles.id) {
			const targetFile = data.payload.targetFile;
			console.log("target file:", targetFile);

			if (targetFile) { // Opened a single file
				if (targetFile.isDir) {
					const auxArray = targetFile.id.split('/').map((dirName, index, array) => {
						const fullPath = array.slice(0, index + 1).join('/');
						return {
							id: fullPath,
							name: dirName,
							isDir: true
						};
					});
					console.log("auxArray: ", auxArray);

					// Fancy loading breadcrumbs
					let auxLoading = [...auxArray];
					auxLoading[auxLoading.length - 1] = null;
					setFolderChain(auxLoading);

					await loadMedia(targetFile.id);
					
					setFolderChain(auxArray);
				} else {
					// Create download url
					const a = document.createElement('a');
					console.log("getMediaDownloadURL() ", targetFile.name, folderChain[folderChain.length - 1].id);
					const DownloadUrl = await getMediaDownloadURL(targetFile.name, folderChain[folderChain.length - 1].id);
					console.log("Mi download url: ", DownloadUrl);
					a.href = DownloadUrl.DownloadUrl;
					a.download = targetFile.name; //
					a.type = "application/octet-stream";
					a.target = "_blank";

					// Add the link to the document and click on it
					document.body.appendChild(a);
					a.click();

					// Remove link and free resources
					document.body.removeChild(a);
					URL.revokeObjectURL(a.href);

				}
			} else { // Opened multiple files
				const targetFiles = data.payload.files;
				console.log("target files:", targetFiles);

				targetFiles.forEach(async file => {
					if (file.isDir) return; // Ignore directories in multiple selection

					// Create download url
					const a = document.createElement('a');
					const DownloadUrl = await getMediaDownloadURL(file.name, folderChain[folderChain.length - 1].id);
					a.href = DownloadUrl.DownloadUrl;
					a.download = file.name;
					a.type = "application/octet-stream";
					a.target = "_blank";

					// Add the link to the document and click on it
					document.body.appendChild(a);
					a.click();

					// Remove link and free resources
					document.body.removeChild(a);
					URL.revokeObjectURL(a.href);
				});
			}
		}
	}, [folderChain]);


	useEffect(() => {
		loadMedia("Replays");
	}, [])

	return (
		<LayoutAlt>
			<Container maxWidth="xl">

				<Box className={classes.section}>
					<FullFileBrowser files={articles} folderChain={folderChain} onFileAction={handleAction} />
				</Box>
			</Container>
		</LayoutAlt>
	);
};

export default Replays;
