/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import SectionImage from 'assets/images/img-user.png';
import ArticleAlt from 'components/article-alt/article-alt';
import ModalNewUser from 'components/modal-new-user/modal-new-user';

import { getMyUsers, getCookie, setSessionCookie, getAppMetadataValue, requireUserID, getRoles } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '100px 0 17px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			display: 'block',
			padding: '150px 0 70px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '78px 0 70px',
		},
		'& > div': {
			flex: '0 0 100%'
		}
	},
	sectionContent: {
		textAlign: 'center',
		maxWidth: '610px',
		margin: 'auto'
	},
	image: {
		display: 'block',
		margin: '15px auto 27px',
		[theme.breakpoints.down('xs')]: {
			// width: '215px',
			margin: '0 auto 25px',
		}
	},
	sectionTitle: {
		letterSpacing: '0',
		margin: '0 0 18px',
		color: '#808191',
		fontWeight: '600',
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
			margin: '0 0 11px',
		}
	},
	sectionEntry: {
		fontSize: '20px',
		fontWeight: '500',
		lineHeight: '1.65',
		letterSpacing: '0',
		[theme.breakpoints.down('xs')]: {
			fontSize: '11px',
			padding: '0 20px',
		}
	},
	sectionBox: {
		margin: 'auto',
		left: '-33px',
		[theme.breakpoints.down('md')]: {
			left: '0'
		}
	},
	sectionCover: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '129px 0 35px',
		[theme.breakpoints.down('md')]: {
			padding: '150px 0 70px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '100px 0 70px',
		},
	},
	sectionBar: {
		textAlign: 'center',
		marginBottom: '76px',
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			bottom: '97px',
			right: '19px',
			marginBottom: '0',
			zIndex: '100',
		}
	},
	articles: {
		margin: '0 -77px',
		[theme.breakpoints.down('lg')]: {
			margin: '0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '0 -15px',
		}
	},
	articlesActions: {
		marginBottom: '13px',
		paddingLeft: '117px',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '44px'
		},
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		},
		'& button': {
			textAlign: 'left',
			padding: '0',
			display: 'inline-block',
			[theme.breakpoints.down('sm')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '12px',
			},
			'& i': {
				[theme.breakpoints.down('xs')]: {
					width: '10px',
					height: '10px',
				}	
			},
			'&:nth-child(1)': {
				width: '125px',
				[theme.breakpoints.down('md')]: {
					width: '90px',
				},
				[theme.breakpoints.down('sm')]: {
					width: '69px',
				}
			},
			'&:nth-child(2)': {
				width: '135px',
				[theme.breakpoints.down('md')]: {
					width: '135px',
				},
				[theme.breakpoints.down('sm')]: {
					width: '135px',
				}

			},
			'&:nth-child(3)': {
				width: '135px',
				[theme.breakpoints.down('md')]: {
					width: '300px',
				}
			}
		}
	},
	sectionPagination: {
		marginTop: '84px',
		[theme.breakpoints.down('xs')]: {
			margin: '90px -15px 0'
		},
		'& .MuiPagination-ul': {
			justifyContent: 'center'
		},
		'& .MuiButtonBase-root': {
			width: '34px',
			height: '34px',
			lineHeight: '34px',
			fontSize: '16px',
			border: 'none',
			background: '#fff',
			margin: '0 5px',
		},
		'& Mui-disabled': {
			background: '#fff'
		},
		'& .Mui-selected': {
			backgroundColor: '#00CCEE !important',
			color: '#fff',
			borderRadius: '50%',
		},
		'& li:last-child': {
			'& button': {
				background: 'none'
			}
		}
	},
	sectionButton: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '0',
			minWidth: '0',
			width: '56px',
			height: '56px',
			padding: '0',
			borderRadius: '50%',
		},
		'& i': {
			display: 'none',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			[theme.breakpoints.down('xs')]: {
				display: 'inline-block',
			},
		}

	}

}));

const Users = () => {
	const classes = useStyles();
	const maxPerPage = 10;
	const [page, setCurrentPage] = useState(1);
	const [openModal, setOpenModal] = useState(false);
	const [filterOutClients, setFilterOutClients] = useState(true);
	const [isSuperadmin, setIsSuperadmin] = useState(false);
	const [firstLoaded, setFirstLoaded] = useState(false);
	const [checkboxDisabled, setCheckboxDisabled] = useState(false);
	const [articles, setArticles] = useState(() => {
		let aux = [];
		for (let i = 0; i < /* maxPerPage */ 5; i++) {
			aux.push({
				id: "1",
				avatar: "loading.jpg",
				name: "loading",
				email: "loading",
				isAdmin: false,
				skeleton: true
			});
		}
		return aux;
	});

	useEffect(()=>{
		async function fetchUsers() {
			setCheckboxDisabled(true);
			const data = await getMyUsers(false, filterOutClients, true);

			//console.log(data)

			let auxArticles = [];
			/* const userId = requireUserID(false); */

			data.forEach(element => {
				const role = getAppMetadataValue(element, "Role");
				const name = (role == "Client") ? element.username : ((role == "Admin") ? element.username + " ✪" : element.username + " 🔧"); // TODO distinguir superadmins
				
				/* if (element.user_id != userId) { */
					auxArticles.push({
						id: element.user_id,
						name: name,
						avatar: element.picture,
						email: element.email,
						isAdmin: role == "Admin",
						skeleton: false
					});
				/* } */
			});
			auxArticles.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
			
			setArticles(auxArticles);	
			setFirstLoaded(true);

			// On load set page to saved cookie
			let currentPage = parseInt(getCookie("userPage"));
			if (!currentPage) 
				currentPage = 1;
			setCurrentPage(currentPage);
			setCheckboxDisabled(false);
		};

		let aux = [];
		for (let i = 0; i < /* maxPerPage */ 5; i++) {
			aux.push({
				id: "1",
				avatar: "loading.jpg",
				name: "loading",
				email: "loading",
				isAdmin: false,
				skeleton: true
			});
		}
		setArticles(aux);

		// List users
		fetchUsers();
	}, [filterOutClients])

	// Correct pagination page if outside bounds
	useEffect(()=>{
		if (page < 1 || Math.ceil(articles?.length / maxPerPage) < page) {
			setCurrentPage(1);
			setSessionCookie("userPage", 1);

			console.log("Invalid page, fixing :)");
		}
	}, [articles, page])
	
	useEffect(async ()=>{
		const userID = requireUserID(true);
		const dataRoles = await getRoles(userID);

		console.log(dataRoles);

		const superadminRole = dataRoles.Roles.find(element => element.id == process.env.REACT_APP_AUTH0_SUPERADMIN_ROLEID);
		setIsSuperadmin(Boolean(superadminRole));
	}, []);


	if (articles.length > 0) {
		return (
			<LayoutAlt activeNav="users">
				<Box className={classes.sectionCover}>
					<Box>
						<Container maxWidth="xl">
							<Box className={classes.sectionBar}>
								<Button
									variant="contained"
									color="secondary"
									className={classes.sectionButton}
									onClick={(e) => {
										e.preventDefault()

										setOpenModal(!openModal)
									}}
								>
									New user

									<i className="ico-plus"></i>
								</Button>
							</Box>

							<Box className={classes.articles}>
								<Box className={classes.articlesActions}>
									<Button variant="text" style={{visibility:'hidden' }}>ID # <i className="ico-arrow-up"></i></Button>

									<Button variant="text">Username</Button>
									{(isSuperadmin && firstLoaded) && (<FormControlLabel control={<Checkbox id='checkbox-teacher' defaultChecked={filterOutClients} disabled={checkboxDisabled}/>} label="Hide clients" onChange={e => {setFilterOutClients(!filterOutClients)}}/>)}

								</Box>

								{articles?.slice(0+ maxPerPage * (page - 1), maxPerPage * page).map((article, index) => {
									return (
										<ArticleAlt
											key={index}
											id={article.id}
											avatar={article.avatar}
											name={article.name}
											email={article.email}
											isAdmin={article.isAdmin}
											skeleton={article.skeleton}
										/>
									)
								})}
							</Box>

							<Pagination
								count={Math.ceil(articles?.length / maxPerPage)} 
								variant="outlined"
								shape="rounded"
								className={classes.sectionPagination}
								page={page}
								onChange={(e, p) => {
									setCurrentPage(p);
									setSessionCookie("userPage", p);
								}}
							/>
						</Container>
					</Box>
				</Box>

				<Modal
					open={openModal}
					onClose={(e) => { setOpenModal(false) }}
				>
					<div><ModalNewUser onClose={(e) => { setOpenModal(false) }} /></div>
				</Modal>
			</LayoutAlt>
		);
	} else {
		return (
			<LayoutAlt>
				<Box className={classes.section}>
					<Box>
						<Container maxWidth="xl">
							<Box className={classes.sectionContent}>
								<Typography
									variant="h4"
									component="h4"
									className={classes.sectionTitle}
								>
									You have not created any users yet.
								</Typography>

								<Typography
									component="p"
									className={classes.sectionEntry}
								>
									Add a <strong>new user</strong> to invite them to your circles.
								</Typography>

								<img
									className={classes.image}
									src={SectionImage}
									alt=""
								/>

								<Button
									variant="contained"
									color="secondary"
									className={classes.sectionButton}
									onClick={(e) => {
										e.preventDefault()

										setOpenModal(!openModal)
									}}
								>New user</Button>
							</Box>
						</Container>
					</Box>
				</Box>
				<Modal
					open={openModal}
					onClose={(e) => { setOpenModal(false) }}
				>
					<div><ModalNewUser onClose={(e) => { setOpenModal(false) }} /></div>
				</Modal>

			</LayoutAlt>
		);
	}
};

export default Users;
